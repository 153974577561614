import { loadOrderFlow } from './orderFlow';

/**
 * Add click listener on table rows that contain <a> tag
 * Sets url to first <a> tag it finds and ignores click if any anchor is clicked
 * If table row has data-available-orderline it will load the order flow
 * @param tableElement
 */

export function clickableTable(tableElement) {
    if (!tableElement) {
        return;
    }

    tableElement.querySelectorAll('tr')
        .forEach((row) => {
            if (!row.classList.contains('clickable-row')) {
                const availableOrderline = row.dataset?.availableOrderline;
                const rowClickout = row.querySelector('a');
                if (rowClickout || !!availableOrderline) {
                    row.classList.add('clickable-row');
                    row.addEventListener('click', (event) => {
                        if (availableOrderline) {
                            event.preventDefault();
                            row.classList.add('loading');
                            try {
                                loadOrderFlow(false, {}, row.dataset).then(() => {
                                    row.classList.remove('loading');
                                });
                            } catch (error) {
                                row.classList.remove('loading');
                                window.open(
                                    rowClickout.href,
                                    rowClickout.target === '_blank' ? '_blank' : '_self',
                                ).focus();
                            }
                            return;
                        }
                        if (event.target.nodeName === 'A' || event.target.parentNode.nodeName === 'A') {
                            return;
                        }
                        window.open(
                            rowClickout.href,
                            rowClickout.target === '_blank' ? '_blank' : '_self',
                        )
                            .focus();
                    });
                }
            }
        });
}

/**
 * Check if a table container has a scrollbar and add the scrollable class
 * On the scroll event the class is removed
 * Needs once : true so the event only triggers once
 * @param tableContainer
 */
export function scrollableTable(tableContainer) {
    if (!tableContainer || tableContainer.classList.contains('scrollable')) {
        return;
    }
    if (tableContainer.scrollWidth > tableContainer.clientWidth) {
        tableContainer.classList.add('scrollable');
        tableContainer.addEventListener('scroll', () => {
            tableContainer.classList.remove('scrollable');
        }, { once: true });
    }
}
